const lang = {
  //address
  address: 'Adresse',
  country: 'Land',
  latitude: 'Breitengrad',
  longitude: 'Längengrad',
  edit_address: 'Adresse bearbeiten',
  street: 'Straße',
  house_number: 'Hausnummer',
  zip_code: 'PLZ',
  city: 'Stadt',

  //device
  device: 'Gerät',
  device_id: 'Geräte-ID',
  device_type: 'Gerätetyp',
  device_type_id: 'Gerätetyp-ID',
  add_device: 'Gerät hinzufügen',
  mac_address: 'MAC-Adresse',

  //notify
  error_loading_data: 'Die notwendigen Daten konnten nicht von der Datenbank abgerufen werden!',
  error_loading_data_mds: 'Die notwendigen Daten konnten nicht von Mondas abgerufen werden!',
  error_sync_metadata: 'Metadaten konnten nicht synchronisiert werden!',
  error_sync_time_series_data: 'Metadaten konnten nicht synchronisiert werden!',
  loading_data: 'Daten werden geladen und aufbereitet! Bitte warten ...',
  ups_error_loading_data: 'Ups! Hier ist etwas schiefgelaufen :(',
  error_updating_data: 'Daten konnten nicht bearbeitet werden!',
  error_adding_data: 'Daten konnten nicht hinzugefügt werden!',
  no_empty_fields: 'Es werden keine leeren Eingaben akzeptiert! Bitte alle Felder ausfüllen!',
  error_set_time_range: 'Bitte Zeitbereich einstellen!',
  success_saving_data: 'Daten wurden erfolgreich gespeichert!',
  error_saving_data: 'Daten konnten nicht gespeichert werden!',
  fill_all_req_fields: 'Bitte alle Pflichfelder* ausfüllen!',
  info_restart_device: 'Gerät wird neu gestartet!',
  info_restart_driver: 'Treiber werden neu gestartet!',
  error_restart_device: 'Gerät konnte nicht neu gestartet werden!',
  error_restart_driver: 'Treiber konnten nicht neu gestartet werden!',
  error_bacnet_scan_not_started: 'Bacnet-Scan konnte nicht gestartet werden!',
  error_bacnet_scan_not_finished: 'Bacnet-Scan-Ergebnis konnte nicht abgerufen werden. Möglicherweise ist der Scan noch nicht abgeschlossen!',
  success_config_on_device: 'Datenpunkte wurden auf dem Gerät konfiguriert!',
  error_config_on_device: 'Datenpunkte konnten nicht auf dem Gerät konfiguriert werden!',
  error_req_config: 'Konfiguration konnte nicht angefordert werden! Bitte Eingabe prüfen!',
  error_load_config: 'Konfiguration konnte nicht geladen werden! Bitte manuell starten!',
  error_req_tags: 'Es konnten keine Tags von der Datenbank geladen werden!',
  error_edit_tag: 'Tag konnten nicht bearbeitet werden!',
  error_adding_tag: 'Tag konnte nicht in der Datenbank hinzugefügt werden!',
  error_adding_project: 'Projekt konnte nicht hinzugefügt werden!',
  error_editing_project: 'Projekt konnte nicht bearbeitet werden!',
  success_saving_presets: 'Presets wurden erfolgreich gespeichert!',
  error_saving_presets: 'Presets konnten nicht zur Datenbank übertragen werden! Bitte erneut versuchen',
  error_adding_preset: 'Preset konnte nicht hinzugefügt werden!',
  error_load_presets: 'Es konnten keine Presets geladen werden!',
  error_load_token: 'Token konnten nicht geladen werden!',
  error_load_token_info: 'Token-Infos konnten nicht geladen werden!',
  error_adding_token: 'Token konnte nicht hinzugefügt werden!',
  error_load_specific_fields: 'Tokenspezifische Felder konnten nicht geladen werden!',
  error_adding_device_type: 'Gerätetyp konnte nicht hinzugefügt werden!',
  error_adding_device: 'Gerät konnte nicht hinzugefügt werden!',
  error_adding_device_shortname: 'Es existiert bereits ein Gerät mit diesem Kürzel!',
  error_searching_device: 'Es konnte kein Gerät mit dieser ID gefunden werden!',
  error_adding_operation_state: 'Betriebszustand konnte nicht hinzugefügt werden!',
  info_operation_state_duplicate: 'Werte können nicht doppelt zugewiesen werden! Betriebszustände und Wertzuweisung auch vergleichen!',
  error_uploading_image: 'Bild konnte nicht hochgeladen werden!',
  error_changing_password: 'Passwort konnte nicht geändert werden!',
  warning_selection_filter: 'Achtung! Es sind Reihen selektiert, die durch den gesetzten Filter nicht sichtbar sind!',
  error_rename_preset_node: 'Preset konnte nicht umbenannt werden!',
  error_empty_name_field: 'Bitte Name vergeben!',
  system_already_exists: 'Diese Anlage gibt es bereits!',
  changes_not_saved: 'Achtung! Änderungen wurden nicht gespeichert!',
  error_updating_structure: 'Struktur konnte nicht aktualisiert werden!',
  error_not_all_data_add_chirpstack_device: 'Es müssen "Kürzel", "APP KEY" und "DEV EUI" für Chirpstack ausgefüllt sein!',
  error_adding_chirpstack_device: 'Gerät konnte nicht zu Chirpstack hinzugefügt werden!',
  success_sending_device_to_chirpstack: 'Gerät wurde erfolgreich an Chirpstack gesendet!',
  error_printer_connection: "Es konnte keine Verbindung zum Labeldrucker hergestellt werden. Bitte überprüfen, ob die 'Browser Print' Software läuft!",
  error_polling_token_state: 'Es konnte kein Synchronisierungsfortschritt geladen werden!',
  error_adding_permission_action: 'Aktion konnte nicht hinzugefügt werden!',
  error_deleting_permission_action: 'Aktion konnte nicht gelöscht werden!',
  error_adding_permission_page: 'Seite konnte nicht hinzugefügt werden!',
  error_deleting_permission_page: 'Seite konnte nicht gelöscht werden!',
  error_adding_permission_role: 'Benutzerrolle konnte nicht hinzugefügt werden!',
  error_deleting_permission_role: 'Benutzerrolle konnte nicht gelöscht werden!',
  error_adding_user: 'Benutzer konnte nicht hinzugefügt werden!',
  error_deleting_user: 'Benutzer konnte nicht gelöscht werden!',
  error_changing_user_state: 'Benutzerstatus konnte nicht gelöscht werden!',
  error_data_incoming_since: 'Datum konnte nicht aktualisiert werden!',
  error_deleting_tag: 'Tag konnte nicht gelöscht werden!',
  system_not_up_to_date: 'Anlagenstruktur ist nicht aktuell!',
  no_datapoints_to_log: 'Es sind keine Datenpunkte zum Logging getaggt!',
  error_datapoint_already_exists: 'Datenpunkt existiert bereits!',
  error_deleting_data: 'Daten konnten nicht gelöscht werden!',
  no_undo_available: 'Es können nicht weitere Schritte rückgängig gemacht werden!',
  error_backup_data: 'Von diesem Schritt konnte kein Backup erstellt werden und kann deshalb nicht rückgängig gemacht werden!',
  error_submit_jour_fixe_data: 'Jour fixe konnte nicht gespeichert werden!',

  //time
  date_time_format: 'dd.MM.yyyy HH:mm',
  date_format: 'dd.MM.yyyy',
  timezone: 'Zeitzone',
  data_from: 'Daten von',
  data_to: 'Daten bis',
  sync_from: 'Sync von',
  sync_to: 'Sync bis',
  last_update: 'Letztes Update',
  last_activity: 'Letzte Aktivität',
  update_interval: 'Updateintervall',
  added_at: 'Hinzugefügt am',
  progress: 'Fortschritt',
  finished_at: 'Beendet am',
  data_incoming_since: 'Dateneingang seit',
  from: 'Von',
  to: 'Bis',
  all_day: 'Ganztätig',

  //control
  abort: 'Abbrechen',
  save: 'Speichern',
  close: 'Schließen',
  retry: 'Nochmal probieren!',
  reset_values: 'Werte zurücksetzen',
  expert_mode: 'Expertenmodus',
  set_tags: 'Tags setzen',
  set_color: 'Farbe festlegen',
  copy_cell: 'Zelleninhalt kopieren',
  autosize_columns: 'Spaltenbreite anpassen',
  tag_menu: 'Tag-Menü',
  all_columns: 'Alle Spalten',
  sync_metadata: 'Metadaten synchronisieren',
  show_values: 'Zeige Werte',
  update_log: 'Log aktualisieren',
  sync_time_series_data: 'Sync Timeseries-Daten',
  restart_device: 'Gerät neu starten',
  restart_driver: 'Treiber neu starten',
  req_config: 'Konfiguration anfordern',
  req_config_manual: 'Konfiguration manuell anfordern',
  config_to_device: 'Konfiguration auf Gerät laden',
  scan_from: 'Scannen von*',
  scan_to: 'Scannen bis*',
  start_bacnet_scan: 'Bacnet-Scan starten',
  get_scan_result_manual: 'Ergebnis manuell anfordern',
  conf_datapoints_on_device: 'Datenpunkte auf Gerät konfigurieren',
  send_settings_to_database: 'Einstellungen auf Datenbank übertragen',
  name_in_button: 'Name in Button anzeigen',
  normalize_needed: 'Normalisieren nötig ',
  do_not_set_operation_state: 'Betriebszustände nicht setzen',
  set_operation_state: 'Betriebszustände setzen',
  set_subscribers: 'Abonnenten festlegen',
  capture_photo: 'Foto aufnehmen',
  read_text: 'Text lesen',
  choose_image: 'Bild auswählen',
  scan_area: 'Bereich lesen',
  logout: 'Ausloggen',
  change_password: 'Passwort ändern',
  rename: 'Umbenennen',
  display_name_de: 'Displayname DE',
  display_name_en: 'Displayname EN',
  yes: 'Ja',
  no: 'Nein',
  delete: 'Löschen',
  update_structure: 'Struktur aktualisieren',
  change_system_tree_format: 'Struktur umschalten',
  load: 'Laden',
  copy_zpl: 'ZPL kopieren',
  send_chirpstack: 'Zu Chirpstack senden',
  copy: 'Kopieren',
  paste: 'Einfügen',
  update_rule_to_newest_version: 'Regel auf neueste Version updaten',
  update_function_to_newest_version: 'Funktion auf neueste Version updaten',
  update_selectors_to_newest_version: 'Selektor auf PROD Version updaten',
  update_colormap_to_newest_version: 'Colormap auf PROD Version updaten',
  add_rule: 'Regel hinzufügen zu',
  add_rules: 'Regeln hinzufügen zu',
  add_function: 'Funktion hinzufügen',
  add_functions: 'Funktionen hinzufügen zu',
  add_selector: 'Selektor von PROD hinzufügen zu ',
  add_selectors: 'Selektoren von PROD hinzufügen zu ',
  add_colormaps: 'Colormaps von PROD hinzufügen zu ',
  add_colormap: 'Colormap von PROD hinzufügen zu ',
  remove_backup_from_database: 'Backup entfernen',
  remove_rule_from_database: 'Backup komplett entfernen',
  add_preset: 'Preset hinzufügen',
  question_german: 'Frage in Deutsch',
  question_english: 'Question in Englisch',
  save_and_exit: 'Speichern und schließen',
  save_and_datapoints: 'Speichern und Datenpunkte zuweisen',
  back: 'Zurück',
  continue: 'Weiter',
  system_successfully_configured: 'System erfolgreich konfiguriert!',
  filter_not_tagged: 'Filter nicht getaggte Datenpunkte',
  toggle_checklist: 'checkliste',
  generate_offer: 'Angebot erstellen',
  upgrade_to_project: 'Auf Projekt upgraden',
  repeat_code: 'Code wiederholen',
  print_label: 'Label drucken',
  show_all_rules: 'Alle Regeln',
  show_rules_under_80_percent: 'Regeln mit Performance < 80%',
  move: 'Element verschieben',
  move_here: 'Element hierher verschieben',
  add_action: 'Aktion hinzufügen',
  add_page: 'Seite hinzufügen',
  actions: 'Aktionen',
  pages: 'Seiten',
  user_roles: 'Benutzerrollen',
  user_role: 'Benutzerrolle',
  role_management: 'Rollenverwaltung',
  actions_and_pages: 'Aktionen und Seiten',
  collapse: 'Zuklappen',
  expand: 'Aufklappen',
  choose_ede_file: 'EDE-Datei auswählen',
  csv_columns: 'CSV-Spalten',
  order_number: 'Auftragsnummer',
  moment: 'Zeitpunkt',
  title: 'Titel',
  resUsers: 'Verantwortlich',
  search: 'Suchen',
  message: 'Nachricht',
  address_to: 'Richten an ...',
  undo: 'rückgängig machen',
  upload: 'Hochladen',
  logging: 'Loggen',
  no_logging: 'Nicht loggen',
  get_2_factor_qr: 'Zwei-Faktor-QR-Code',
  list: 'Liste',
  manual: 'Manuell',
  duplicate: 'Duplizieren',
  iothub_device_name: 'IoT-Hub Gerätename',
  add_updater_iotHub: 'Updater (IoT-Hub Gerät) anlegen',
  restart_system: 'Neustart durchführen',
  start_scan: 'Scan starten',
  default_properties: 'Standard Properties',
  all_properties: 'Alle properties',
  edit_clients: 'Clients bearbeiten',
  edit_objects: 'Objekte bearbeiten',
  log_interval: 'Log-Intervall',
  send_logs_interval: 'Sendeintervall',
  save_config: 'Konfiguration speichern',
  delete_logs: 'Logs löschen',
  get_ib_logs: 'IntelliBridge logs laden',
  get_influx_logs: 'InfluxDB logs laden',
  get_tc_logs: 'TwinCAT logs laden',
  fetch_all_properties: 'Lade alle Properties',

  //menu names
  systems: 'Anlagen',
  datapoint_manager: 'Datenpunktmanager',
  projects: 'Projekte',
  building: 'Gebäude',
  buildings: 'Gebäude',
  devices: 'Geräte',
  device_types: 'Gerätetypen',
  info: 'Info',
  settings: 'Einstellungen',
  config: 'Konfiguration',
  datapoints: 'Datenpunkte',
  restart: 'Neustart',
  operation_states: 'Betriebszustände',
  system_configurator: 'Anlagen-Konfigurator',
  rules_version_management: 'Regel Versionsverwaltung',
  offer_config: 'Angebotskonfiguration',
  raw_system_editor: 'Anlagen-Editor',
  permission_manager: 'Berechtigungsverwaltung',

  //table headers
  dp_tablename: 'dp_Tabellenname',
  val_tablename: 'val_Tabellenname',
  object_instance: 'Objektinstanz',
  object_type: 'Objekttyp',
  dp_key: 'dp-Schlüssel',
  unit: 'Einheit',
  units: 'Einheiten',
  description: 'Beschreibung',
  designation: 'Bezeichnung',
  token_type_id: 'Token-Typ-ID',
  token_type_name: 'Token-Typ-Name',
  short: 'Kürzel',
  type: 'Typ',
  value: 'Wert',
  name: 'Name',
  failure_message: 'Fehlermeldung',
  building_id: 'Gebäude-ID',
  device_object_instance: 'Geräte-Objektinstanz',
  object_name: 'Objektname',
  cov_increment: 'COV-Inkrement',
  maximum: 'Maximalwert',
  minimum: 'Minimalwert',
  bacnet_object_type: 'Bacnet-Objekttyp',
  operation_state: 'Betriebszustand',
  normalized: 'Normalisiert',
  priority: 'Priorität',
  operator: 'Operator',
  text: 'Text',
  subscriber: 'Abonnent',
  subscribers: 'Abonnenten',
  no_subscribers: 'Keine Abonnenten',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  instance: 'Instanz',
  lastValue: 'Letzter Wert',
  value_progression: 'Wertentwicklung',
  category: 'Kategorie',
  upToDate: 'Ist aktuell',
  question: 'Frage',
  label: 'Bezeichnung',
  initial_costs: 'Erstkosten',
  further_costs: 'Funktionsgleiche Anlagen',
  count: 'Anzahl',
  favorite: 'Favorit',
  disabled: 'Deaktiviert',
  files: 'Dateien',
  your_tasks: 'Deine Aufgaben',
  notes: 'Notizen',
  lastValueTimestamp: 'Letzter Wert Zeitpunkt',
  dp_add_datetime: 'Datenpunkt angelegt',
  state: 'Status',
  connection_state: 'Verbindungsstatus',

  //headers
  add_project: 'Projekt/Angebot hinzufügen',
  edit_project: 'Projekt bearbeiten',
  edit_system: 'System bearbeiten',
  edit: 'Bearbeiten',
  add_building: 'Gebäude hinzufügen',
  specific: 'Spezifisch',
  metadata_log: 'Metadaten Log',
  time_series_log: 'Zeitreihen log',
  adding_tag_to: 'Hinzufügen eines Tags zu:',
  add_token: 'Token hinzufügen',
  add_device_type: 'Gerätetyp hinzufügen',
  add_operation_state: 'Betriebszustand hinzufügen',
  value_mapping: 'Wertzuweisung',
  user_fields: 'Benutzerfelder',
  color: 'Farbe',
  processed_files: 'Verarbeitete Dateien',
  values: 'Werte',
  stored: 'Gespeichert',
  data_volume: 'Gespeichert',
  csvs_in_queues: 'CSVs in Warteschlange',
  devices_offline: 'Geräte offline',
  last_24_h: 'Letzten 24 Stunden',
  assigned: 'Zugewiesen',
  receivers: 'Empfänger',
  reports: 'Berichte',
  client: 'Auftraggeber',
  catalog: 'Katalog',
  service: 'Dienstleistung',
  add_user_role: 'Benutzerrolle hinzufügen',
  add_user: 'Benutzer hinzufügen',
  individually_unlocked_actions: 'Individuell freigeschaltete Aktionen',
  individually_unlocked_pages: 'Individuell freigeschaltete Seiten',
  add_datapoint: 'Datenpunkt hinzufügen',
  scan_devices: 'Geräte scannen',

  //others
  project: 'Projekt',
  project_id: 'Projekt-ID',
  adding: 'Hinzufügen',
  removing: 'Entfernen',
  driver: 'Treiber',
  req_result_auto: 'Ergebnis wird automatisch abgerufen in',
  folder: 'Ordner',
  property: 'Eigenschaft',
  dashed: 'Gestrichelt',
  dotted: 'Gepunktet',
  solid: 'Solide',
  corresponds_to: 'entspricht',
  number_filtered_rows: 'Anzahl gefilterter Zeilen',
  number_selected_rows: 'Anzahl selektierter Zeilen',
  enter_valid_mail: 'Bitte eine valide E-Mail-Adresse eingeben',
  enter_mail: 'Bitte eine E-Mail-Adresse eingeben',
  enter_password: 'Bitte ein Passwort eingeben',
  mail_or_password_wrong: 'Mail-Adresse oder Passwort falsch! Bitte überprüfen!',
  two_factor_token_wrong: 'Zwei-Faktor-Token is falsch!',
  password: 'Passwort',
  mail: 'Mail-Adresse',
  firstname: 'Vorname',
  lastname: 'Nachname',
  unknown_error: 'Es ist ein unbekannter Fehler aufgetreten!',
  password_old: 'Altes Passwort',
  password_new: 'Neues Passwort',
  old_pass_not_ok: 'Passwort stimmt nicht mit dem bisherigen Passwort überein!',
  pass_length_error: 'Passwort muss mindestens 8 Zeichen lang sein!',
  pass_digit_error: 'Passwort muss mindestens eine Zahl enthalten!',
  pass_upper_lower_case: 'Passwort muss Groß- und Kleinbuchstaben enthalten!',
  enter_login_data: 'Bitte geben Sie Ihre Anmeldedaten ein',
  technical_monitoring: 'TÜV SÜD Technisches Monitoring',
  default_value: 'Standardwert',
  unsaved_data: 'Achtung! Änderungen wurden nicht gespeichert. Sind Sie sicher, dass sie die Seite verlassen wollen?',
  selected_value_default: 'Standardwert durch klicken selektieren',
  local: 'Lokal',
  sync_full: 'Alle Datenpunkte werden mit den Timeseries-Daten des gewählten Zeitbereichs synchronisiert',
  sync_only_empty: 'Es werden nur die Datenpunkte synchronisiert, für die zum Startzeitpunkt („Daten von“) noch keine Timeseries-Daten in Mondas vorhanden sind',
  loading_last_values: 'Werte werden geladen',
  user: 'Benutzer',
  ede_takes_time: 'Verarbeiten der EDE-Datei kann einige Zeit in Anspruch nehmen! Bitte warten ...',
  todo_done_by: 'Von <<FIRSTNAME>> <<LASTNAME>> am <<DATE>> erledigt!',
  or: 'oder',
  data_input: 'Dateneingang',
  dashboard_published: 'Dashboard freigegeben',
  german: 'Deutsch',
  english: 'Englisch',
  software_version: 'Software-Version',
  scan_date: 'Scan-Zeitpunkt',

  //page title
  device_list: 'Geräteliste',
  device_config: 'Gerätekonfiguration',
  project_datapoint_manager: 'Projekt-Datenpunktmanager',
  project_systems: 'Projekt-Anlagen',
  project_buildings: 'Projekt-Gebäude',
  project_token: 'Projekt-Token',
  project_devices: 'Projekt-Geräte',
  project_info: 'Projekt-Info',
  permission_denied: 'Zugriff verweigert!',
  no_permission: 'Sie haben nicht die nötige Berechtigung für diese Seite!',
  optical_character_recognition: 'Optische Texterkennung',
  are_you_sure: 'Sind Sie sich sicher?',
  auto_interim_reports: 'Automatisches Kurzprotokoll',
  manual_reports: 'Manuelles Kurzprotokoll',
  final_report: 'Abschlussbericht',
  mds_rules: 'Mondas Regeln',
  mds_functions: 'Mondas Funktionen',
  mds_selectors: 'Mondas Selektoren',
  mds_colormaps: 'Mondas Colormaps',
  rules: 'Regeln',
  rule: 'Regel',
  functions: 'Funktionen',
  function: 'Funktion',
  offers: 'Angebote',
  offer: 'Angebot',
  confirm_deleting: 'Bestätigen Sie das Löschen von',
  permission_management: 'Berechtigungsverwaltung',
  calendar: 'Kalender',
  change_device_project: 'Gerätezuweisung ändern',
  selectors: 'Selektoren',
  selector: 'Selektor',

  //tooltips
  delete_preset_button: 'Preset-Button löschen',
  duplicate_preset_button: 'Preset-Button duplizieren',
  add_preset_button: 'Preset-Button hinzufügen',
  set_preset_image: 'Preset-Bild setzen',

  //notifications
  notifications: 'Benachrichtigungen',
  mark_all_as_read: 'Alle als gelesen markieren',
  new: 'Neu Benachrichtigungen',
  before_that: 'Gelesene Benachrichtigungen',
  no_notifications: 'Keine Benachrichtigungen vorhanden',
  delete_all: 'Alle Benachrichtigungen löschen',
  new_message: 'Neue Nachricht!',
  new_message_from: 'Du hast eine neue Nachricht von',
  in_todo: 'in Aufgabe',
  of_project: 'von Projekt',
  new_todo: 'Neue Aufgabe!',
  new_todo_from: 'Dir wurde eine neue Aufgabe zugeteilt von',
};

export default lang;
